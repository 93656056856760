<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="card">

              <!-- Logo -->
              <div class="card-header pt-4 pb-4 text-center " style="background-color: #e8f0fe;">
                <a href="index.html">
                  <span><img height="40" alt="" src="../../assets/images/logo-dark.png"></span>
                </a>
              </div>

              <div class="card-body p-4">

                <div class="text-center w-75 m-auto">
                  <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Sign In</h4>
                  <p class="text-muted mb-4">Enter your email address and password to access admin
                    panel.</p>
                </div>

                <form @submit.prevent="handleSubmit">

                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input :class="{'is-invalid': submitted && errors.has('email')}" class="form-control" id="email" name="email" placeholder="Enter your email" type="email" v-model="credentials.email" v-validate="'required|email'">
                    <div class="invalid-feedback" v-if="submitted && errors.has('email')">{{
                                            errors.first('email') }}
                    </div>
                  </div>

                  <div class="form-group">
                    <a class="text-muted float-right" href="pages-recoverpw.html"><small>Forgot your
                        password?</small></a>
                    <label for="password">Password</label>
                    <div class="input-group input-group-merge">
                      <input :class="{'is-invalid': submitted && errors.has('password')}" class="form-control" id="password" name="password" placeholder="Enter your password" type="password" v-model="credentials.password" v-validate="'required'">
                      <div class="input-group-append" data-password="false" style="cursor: pointer">
                        <div class="input-group-text">
                          <span class="password-eye"></span>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="submitted && errors.has('password')">{{
                                                errors.first('password') }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group mb-3">
                    <div class="custom-control custom-checkbox">
                      <input checked class="custom-control-input" id="checkbox-signin" type="checkbox">
                      <label class="custom-control-label" for="checkbox-signin">Remember
                        me</label>
                    </div>
                  </div>

                  <div class="form-group mb-0 text-center">
                    <button class="btn btn-primary" type="submit"> Log In</button>
                  </div>

                </form>
              </div> <!-- end card-body -->
            </div>
            <!-- end card -->
            <!-- end row -->

          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <footer class="footer footer-alt">
    </footer>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      submitted: false,
      credentials: {
        email: '',
        password: ''
      }
    };
  },
  mounted() {
    $("[data-password]").on('click', function () {
      if ($(this).attr('data-password') == "false") {
        $(this).siblings("input").attr("type", "text");
        $(this).attr('data-password', 'true');
        $(this).addClass("show-password");
      } else {
        $(this).siblings("input").attr("type", "password");
        $(this).attr('data-password', 'false');
        $(this).removeClass("show-password");
      }
    });
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;
      this.$validator.validate()
        .then(valid => {
          if (valid) {
            this.login();
          }
        });
    },
    async login() {
      try {
        let reply = await this.$http.post('auth/login', this.credentials);
        this.loginSuccessful(reply);
      } catch (e) {
        this.loginFailed(e);
      }
    },
    loginSuccessful(reply) {
      let token = reply.data.token;
      if (!token) {
        this.loginFailed();
        return;
      }

      this.$store.commit("LOGIN", reply.data);
      this.$router.replace(this.$route.query.redirect || '/');
      this.$toastr.s("Successfully logged in!", 'Success');
    },
    loginFailed(err) {
      let data = err.response ? err.response : null;
      if (data && data.status === 404) {
        this.$toastr.e("Profile not found!", "Error");
      } else if (data && data.status === 403) {
        this.$toastr.e(data.data.error, "Error");
      } else {
        this.$toastr.e("Login failed", "Error");
      }
      this.$store.dispatch('logout');
      delete localStorage['mrel-token'];
    }
  }
}
</script>

<style scoped>
.authentication-bg {
  background-image: url(../../assets/images/bg-pattern-light.svg);
  background-size: cover;
  background-position: center;
}
</style>
